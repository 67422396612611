import React, { useEffect, useState, useCallback, useMemo } from "react"
import Input from "../form/elements/Input"
import Button from "../common/Button"

const Calculator = () => {
  const searchParams = window.location.search
  const queryParams = useMemo(
    () => new URLSearchParams(searchParams),
    [searchParams]
  )

  const [globals, setGlobals] = useState({
    keyword: queryParams.get("keyword") || "",
    searchVolume: Number(queryParams.get("searchVolume")) || 1000,
    requestsRate: Number(queryParams.get("requestsRate")) || 10,
    completionRate: Number(queryParams.get("completionRate")) || 50,
    revenuePerCompletion:
      Number(queryParams.get("revenuePerCompletion")) || 100,
  })

  useEffect(() => {
    queryParams.set("keyword", globals.keyword)
    queryParams.set("searchVolume", globals.searchVolume)
    queryParams.set("requestsRate", globals.requestsRate)
    queryParams.set("completionRate", globals.completionRate)
    queryParams.set("revenuePerCompletion", globals.revenuePerCompletion)

    window.history.pushState({}, "", "?" + queryParams.toString())
  }, [globals, queryParams])

  const [tableData, setTableData] = useState([
    {
      position: 1,
      clickDistribution: 27, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 2,
      clickDistribution: 16, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 3,
      clickDistribution: 11, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 4,
      clickDistribution: 8, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 5,
      clickDistribution: 6, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 6,
      clickDistribution: 5, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 7,
      clickDistribution: 4, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 8,
      clickDistribution: 3, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 9,
      clickDistribution: 3, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
    {
      position: 10,
      clickDistribution: 2, // <--- changeable
      clicks: 0,
      requests: 0,
      completions: 0,
      revenue: 0,
    },
  ])

  const updateTableData = useCallback(() => {
    setTableData(prevTableData => {
      const newTableData = [...prevTableData]
      newTableData.forEach((_, i) => {
        newTableData[i].clicks = Math.round(
          (newTableData[i].clickDistribution / 100) * globals.searchVolume
        )
        newTableData[i].requests = Math.round(
          (newTableData[i].clicks * globals.requestsRate) / 100
        )
        newTableData[i].completions = Math.round(
          (newTableData[i].requests * globals.completionRate) / 100
        )
        newTableData[i].revenue = Math.round(
          newTableData[i].completions * globals.revenuePerCompletion
        ).toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
        })
      })
      return newTableData
    })
  }, [globals])

  useEffect(() => {
    updateTableData()
  }, [globals, updateTableData])

  function handlePrint() {
    const printWindow = window.open("", "_blank")
    const tableHtml = document.querySelector(".overflow-x-auto").outerHTML
    printWindow.document.write(`
      <html>
        <head>
          <title>Umsatz-Rechner ${globals.keyword} SEO Premium Agentur </title>
          <style>
            /* Add any additional styles for printing the table here */
          </style>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://cdn.tailwindcss.com"></script>
          <script>
            tailwind.config = {
              theme: {
                {
                  colors: {
                    "brand-yellow": "#F7DC69",
                    "brand-turquoise": "#7CE7D7",
                    "brand-pink": "#E782C4",
                    "brand-yellow-dark": "#f1c60d",
                    "brand-turquoise-dark": "#25c9b0",
                    "brand-pink-dark": "#cc2793",
                  },
                  screens: {
                    xxs: "380px",
                    xs: "480px",
                  },
                }
              }
            }
          </script>
        </head>
        <body class="bg-gray-100 font-sans leading-normal tracking-normal">
    
          <div class="flex justify-center mb-4">
            <img src="https://www.seo-premium-agentur.de/ms-icon-310x310.png" alt="" class="h-32 w-32">
          </div>

          <div class="text-lg text-gray-700 text-center mt-4 font-bold">
            <p class="mb-2 text-center">Keyword: ${globals.keyword}</p>
          </div>

          <div class="text-sm">
            ${tableHtml.replace(/\bshadow\b/g, "").replace(/\bborder\b/g, "")}
          </div>
            
          <div class="text-xs text-gray-700 text-center mt-4">
            <p class="mb-2 text-center">
              Impressum<br>
              Angaben gemäß § 5 TMG<br>
              Oliver E. Bahm<br>
              Seo Premium Agentur<br>
              Haigernstraße 58<br>
              74388 Talheim bei Heilbronn<br>
              Kontakt<br>
              Telefon: +49 163 4580757<br>
              Büro: +49 7133 901 4343<br>
              E-Mail: kontakt@seo-premium-agentur.de
            </p>
          </div>
        </body>
      </html>
    `)
    setTimeout(() => {
      printWindow.document.close()
      printWindow.print()
    }, 1000)
    printWindow.onafterprint = () => {
      printWindow.close()
    }
  }

  const classes = {
    tableBodyTh: "px-6 py-4 text-left text-sm font-semibold text-gray-900",
    tableBodyTd: "px-6 py-4 text-sm font-sm text-gray-900",
  }

  const jsx = (
    <div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="mb-4">
          <Input
            onChange={e => setGlobals({ ...globals, keyword: e.target.value })}
            value={globals.keyword}
            label="Keyword"
            tooltip="Hier geben Sie einfach Ihr Keyword ein damit man dann beim Ausdruck weiß um welches es sich gehandelt hat. "
          />
        </div>
        <div className="mb-4">
          <Input
            onChange={e =>
              setGlobals({ ...globals, searchVolume: e.target.value })
            }
            value={globals.searchVolume}
            label="Suchvolumen"
            tooltip={
              <>
                Entweder Sie wissen bereits Ihr Suchvolumen oder können dies mit
                dem kostenlosen Seo Tool von Seobility selbst ermitteln. Dazu
                nutzen Sie das kostenlose SEO Tool von SEObility! Der Link dazu:
                <a
                  href="https://www.seobility.net/de/?px=2&a_aid=62272325ddade"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  https://www.seobility.net/de/?px=2&a_aid=62272325ddade
                </a>
              </>
            }
          />
        </div>
        <div className="mb-4">
          <Input
            onChange={e =>
              setGlobals({ ...globals, requestsRate: e.target.value })
            }
            value={globals.requestsRate}
            label="Anfragen / Conversion in %"
            tooltip={
              <>
                Geben Sie hier bitte ein wie viele Anfragen bzw. Conversion Sie
                erwarten! Im Durchschnitt je Branche etwas unterschiedlich von
                ca. 3 - 7 % des Suchvolumens."
                <br />
                Hinweis: Conversionraten auf Webseiten und Onlineshops sind je
                nach Branche, Produkte und Dienstleistungen sehr
                unterschiedlich: Shisha Tabak Onlineshop ca. 24 %,
                Damenbekleidung ca. 65%, Bücher ca. 69 %, Spielwaren 63 %,
                Immobilien ca. 75 %, Unterhaltungsshows ca. 30 %.
                <br />
                Entweder Sie kennen Ihre Conversion oder geben Erfahrungswerte
                anderer Unternehmen als Richtwert ein. Alternativ vereinbaren
                Sie einen Termin mit mir und wir beleuchten Ihr Projekt
                gemeinsam ganz unverbindlich! Die obigen genannten Werte stammen
                von Webseiten und Onlineshops unserer Kunden.
              </>
            }
          />
        </div>
        <div className="mb-4">
          <Input
            onChange={e =>
              setGlobals({ ...globals, completionRate: e.target.value })
            }
            value={globals.completionRate}
            label="Abschlüsse / Verkäufe in %"
            tooltip="Wenn Sie einen Shop haben, geben Sie hier 100 % ein, da die Zahl der Conversion entscheidend ist wie viel Umsatz Sie machen. Wenn Sie aber eine beratendes Gewerbe haben z. B. Immobilien  dann ist der Verkauf von Ihrer Abschlußquote abhängig und Sie geben 25 - 100 % ein. Beispiel: Wenn Sie von 4 Beratungen nur 1 Kunde abschließen, dann geben Sie 25 % ein usw.!"
          />
        </div>
        <div className="mb-4">
          <Input
            onChange={e =>
              setGlobals({ ...globals, revenuePerCompletion: e.target.value })
            }
            value={globals.revenuePerCompletion}
            label="Umsatz pro Abschluss in €"
            tooltip="Hier geben Sie ein was Sie im Durchschnitt an einem Kunden verdienen. Seien Sie einfach realistisch und nehmen den Durchschnitt damit das Ergebnis auch korrekt ist."
          />
        </div>
        <div className="flex items-center">
          <Button
            onClick={handlePrint}
            text="Kalkulation drucken"
            className="w-full"
          />
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className={classes.tableBodyTh}>Position</th>
                <th className={classes.tableBodyTh}>Klickverteilung in % </th>
                <th className={classes.tableBodyTh}>Klicks pro Monat</th>
                <th className={classes.tableBodyTh}>Anfragen pro Monat</th>
                <th className={classes.tableBodyTh}>Abschlüsse pro Monat</th>
                <th className={classes.tableBodyTh}>
                  Erwarteter Umsatz pro Monat
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {tableData.map((row, i) => (
                <tr key={i}>
                  <td className={classes.tableBodyTd}>{row.position}</td>
                  <td>
                    <Input
                      value={tableData[i].clickDistribution}
                      onChange={e => {
                        const newValue = parseFloat(e.target.value)
                        if (!isNaN(newValue)) {
                          const newTableData = [...tableData]
                          newTableData[i].clickDistribution = newValue
                          setTableData(newTableData)
                          updateTableData()
                        }
                      }}
                    />
                  </td>
                  <td className={classes.tableBodyTd}>{row.clicks}</td>
                  <td className={classes.tableBodyTd}>{row.requests}</td>
                  <td className={classes.tableBodyTd}>{row.completions}</td>
                  <td className={classes.tableBodyTd}>{row.revenue}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

  return jsx
}

export default Calculator

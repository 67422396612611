import React from "react"
import Calculator from "../components/calculator/Calculator"
import Layout from "../components/layouts/Default"
import H1 from "../components/typography/H1"
import P from "../components/typography/P"
import Container from "../components/common/Container"
import Button from "../components/common/Button"
import { ShareIcon } from "@heroicons/react/outline"
import SocialShare from "../components/common/SocialShare"

const title = "Umsatz-Rechner"
const desc =
  "Mit diesem von uns entwickeltem Tool dem „Umsatzrechner“ können Sie jedes Keyword auf Wirtschaftlichkeit berechnen."

const CalculatorPage = ({ location }) => {
  const sharePage = async () => {
    try {
      await navigator.share({
        title: document.title,
        url: window.location.href,
      })
    } catch (error) {
      console.error("Error sharing:", error)
    }
  }

  const isClient = typeof window !== "undefined"

  return (
    <Layout location={location} title={title} desc={desc}>
      <Container noPadding className="my-8">
        <H1 className="mb-5">Umsatz-Rechner</H1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <P className="mb-3">
              Mit diesem von uns entwickeltem Tool dem „Umsatzrechner“ können
              Sie jedes Keyword auf Wirtschaftlichkeit berechnen.
            </P>
            <P className="mb-3">
              Denn es gibt nichts schlimmeres, als wenn Sie ein falsches Keyword
              SEO optimieren und es letztlich Ihnen keinen Umsatz bringt!
            </P>
            <P className="mb-3">
              Falls Sie Fragen zum Tool haben, oder Unterstützung benötigen,
              dann schreiben Sie einfach über WhatsApp 0163 4580 757 an Oliver
              E. Bahm persönlich.
            </P>
            <P className="mb-8">
              Oder vereinbaren einen Termin und besprechen Ihre Fragen.
            </P>
            <Button
              text="Kostenlosen Termin vereinbaren"
              href="https://calendly.com/oliver-e-bahm/umsatzrechner"
              className={"mb-8"}
            />
          </div>
          <div>
            <P className="mb-4">
              Teilen Sie Sie diese Seite, damit jeder dieses Tool kostenlos
              nutzen kann!
            </P>

            <div>
              <SocialShare
                showTitle={false}
                center={false}
                sharingText="Umsatz-Rechner von SEO Premium Agentur Stuttgart - Jetzt kostenlos nutzen!"
              />
            </div>

            {isClient && navigator?.share && (
              <Button
                text={
                  <>
                    <ShareIcon className="h-6 w-6 mr-2" />
                    Jetzt teilen
                  </>
                }
                onClick={sharePage}
                className={"mt-4"}
                small
                white
              />
            )}
          </div>
        </div>
      </Container>
      <Container className="my-0" noPadding>
        {isClient && <Calculator />}
      </Container>
    </Layout>
  )
}

export default CalculatorPage
